import React from 'react';
import ComingSoon from '../../components/coming-soon';
import Layout from "../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../components/seo"
import ProTabs from '../../components/pro-tabs'
import {tabsContent} from "../../lib/pro-tabs-content"

const WhiteLabel = () => {
  const comingSoonDetails = {
    backgroundImage: require('../../images/pro-banner8@2x.jpg'),
    heading: "Check-in your members quickly and securely",
    subheading: "With both physical and digital membership cards, our memberships solution lets you quickly issue membership credentials, validate them using our handy phone app, or even query our API to integrate with automated gates and physical entry points. Additional fees apply for physical membership cards and API queries."
  }

  return(
    <Layout>
      <SEO 
        title="Membership Cards | Billsby Pro | Take your subscription business to the next level" 
        description="Membership Cards and other Billsby Pro features let you take your subscription business to the next level." 
        url="https://www.billsby.com/pro/membership-cards"
      />

      <ComingSoon 
        backgroundImage={comingSoonDetails.backgroundImage}
        heading={comingSoonDetails.heading}
        subheading={comingSoonDetails.subheading} />
      <ProTabs content={tabsContent} />
    </Layout>
  )
}

export default WhiteLabel;